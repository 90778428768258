import { __decorate } from "tslib";
import { Vue, Component, Inject } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';
import JobsList from '@/components/shared/JobsList.vue';
import BackupsListModal from '@/components/shared/BackupsListModal.vue';
import BackupsComparisonApi from '@/services/api/backups-comparison.api';
import BackupSelectModal from '@/components/BackupSelectModal.vue';
let BackupsComparisonNewPage = class BackupsComparisonNewPage extends Vue {
    constructor() {
        super(...arguments);
        this.name = '';
        this.description = '';
        this.firstBackup = null;
        this.secondBackup = null;
        this.firstStorageId = null;
        this.secondStorageId = null;
        this.loading = false;
    }
    async selectBackup(backupNumber) {
        try {
            const selectedBackupAndStorage = await this.$modal.show(BackupSelectModal, {
                organizationId: this.organizationId,
            });
            if (!selectedBackupAndStorage) {
                return;
            }
            switch (backupNumber) {
                case 'first':
                    this.firstBackup = selectedBackupAndStorage.backup;
                    this.firstStorageId = selectedBackupAndStorage.storageId;
                    break;
                case 'second':
                    this.secondBackup = selectedBackupAndStorage.backup;
                    this.secondStorageId = selectedBackupAndStorage.storageId;
                    break;
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    async create() {
        try {
            this.loading = true;
            this.$v.$touch();
            if (this.$v.$anyError) {
                return;
            }
            if (!this.firstBackup || !this.secondBackup) {
                this.$toast.error('Select 2 backups to compare!');
                return;
            }
            const { name, description, firstBackup, secondBackup, organizationId, firstStorageId, secondStorageId } = this;
            await BackupsComparisonApi.create({
                name,
                description,
                organizationId,
                firstBackupId: firstBackup.id,
                secondBackupId: secondBackup.id,
                firstBackupStorageId: firstStorageId,
                secondBackupStorageId: secondStorageId,
            });
            this.$toast.success('Comparison successfully queued');
            this.$router.push({
                name: 'backups-comparison.index',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], BackupsComparisonNewPage.prototype, "organizationId", void 0);
__decorate([
    Validate({ required })
], BackupsComparisonNewPage.prototype, "name", void 0);
BackupsComparisonNewPage = __decorate([
    Component({
        components: {
            JobsList,
            BackupsListModal,
        },
    })
], BackupsComparisonNewPage);
export default BackupsComparisonNewPage;
