import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BackupsList from '@/components/shared/BackupsList.vue';
import VueModal from '@/classes/vue-modal';
import BackupUtils from '@/utils/backup.utils';
let BackupSelectModal = class BackupSelectModal extends VueModal {
    constructor() {
        super(...arguments);
        this.result = null;
    }
    async onBackupSelected(backup) {
        try {
            const storage = await BackupUtils.getStorageForBackup(backup);
            if (!storage) {
                return;
            }
            this.result = {
                backup,
                storageId: storage.id,
            };
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    onModalHidden() {
        this.resolved(this.result);
    }
};
__decorate([
    Prop()
], BackupSelectModal.prototype, "organizationId", void 0);
BackupSelectModal = __decorate([
    Component({
        components: {
            BackupsList,
        },
    })
], BackupSelectModal);
export default BackupSelectModal;
